import Link from '@atoms/Link/Link';
import paths from '@constants/paths';
import { trackCartPreviewEmptyStateLinkClick } from '@helpers/analyticsHelpers/trackCartPreview';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { StyledEmptyCart, StyledHeading, StyledTextWrapper } from '@molecules/EmptyCart/EmptyCart.styles';
import { useEffect } from 'react';
import trackCartEmpty from '@helpers/analyticsHelpers/trackCartEmpty';
import useCart from '@hooks/useCart';
import Text from '@atoms/Text/Text';
import useCustomer from '@hooks/useCustomer';
import useResponsive from '@hooks/useResponsive';

import EmptyBag from '@icons/EmptyBag.svg';
import Icon from '@atoms/Icon/Icon';

interface Props {
  fromCartPage: boolean;
}

const EmptyCart = ({ fromCartPage }: Props) => {
  const { cart, hasFetchedCart } = useCart();
  const { customer } = useCustomer();
  const isCartEmpty = !cart?.products.length;
  const { t } = useTranslation();
  const { fromDesktop, isMobile } = useResponsive();

  useEffect(() => {
    if (hasFetchedCart && isCartEmpty) {
      trackCartEmpty();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetchedCart]);

  return (
    <StyledEmptyCart inCartPage={fromCartPage}>
      {fromCartPage && (
        <StyledHeading variant="h1">
          <Trans i18nKey="cartpreview:cart->empty->title" />
        </StyledHeading>
      )}
      {!fromCartPage && (
        <Text type="subhead" size="large">
          <Trans i18nKey="cartpreview:cart->empty->title" />
        </Text>
      )}
      <Icon svg={EmptyBag} size={fromCartPage ? (isMobile ? 240 : 320) : 160} data-testid="empty-cart-icon" />
      <StyledTextWrapper>
        <Text type="body" size={fromCartPage ? 'medium' : 'small'}>
          {!fromDesktop && <Trans i18nKey="cartpreview:cart->empty->mobileText" />}
          {fromDesktop && (
            <>
              {customer?.isAnonymous ? (
                <Text type="body" size="small">
                  <Trans
                    i18nKey="cartpreview:cart->empty->anonymousText"
                    components={[
                      <Link
                        href={paths.USER_LOGIN}
                        shallow
                        key={0}
                        onClick={() => trackCartPreviewEmptyStateLinkClick(t('cartpreview:editorSuggestions->logIn'))}
                      >
                        {}
                      </Link>,
                      <span key={1} />,
                    ]}
                  />
                </Text>
              ) : (
                <Text type="body" size="small">
                  {customer?.isB2BCustomer ? (
                    <Trans
                      i18nKey="cartpreview:cart->empty->loggedInTextB2B"
                      components={[
                        <Link
                          href={paths.MY_LISTS}
                          shallow
                          key={0}
                          onClick={() =>
                            trackCartPreviewEmptyStateLinkClick(t('cartpreview:editorSuggestions->myLists'))
                          }
                        >
                          {}
                        </Link>,
                        <Link
                          href={paths.ACCOUNT_ORDERS}
                          shallow
                          key={1}
                          onClick={() =>
                            trackCartPreviewEmptyStateLinkClick(t('cartpreview:editorSuggestions->myOrders'))
                          }
                        >
                          {}
                        </Link>,
                      ]}
                    />
                  ) : (
                    <Trans
                      i18nKey="cartpreview:cart->empty->loggedInText"
                      components={[
                        <Link
                          href={paths.MY_COMMON_ITEMS}
                          shallow
                          key={0}
                          onClick={() =>
                            trackCartPreviewEmptyStateLinkClick(t('cartpreview:editorSuggestions->myCommonItems'))
                          }
                        >
                          {}
                        </Link>,
                        <Link
                          href={paths.MY_LISTS}
                          shallow
                          key={1}
                          onClick={() =>
                            trackCartPreviewEmptyStateLinkClick(t('cartpreview:editorSuggestions->myLists'))
                          }
                        >
                          {}
                        </Link>,
                        <Link
                          href={paths.ACCOUNT_ORDERS}
                          shallow
                          key={2}
                          onClick={() =>
                            trackCartPreviewEmptyStateLinkClick(t('cartpreview:editorSuggestions->myOrders'))
                          }
                        >
                          {}
                        </Link>,
                      ]}
                    />
                  )}
                </Text>
              )}
            </>
          )}
        </Text>
      </StyledTextWrapper>
    </StyledEmptyCart>
  );
};
export default EmptyCart;
