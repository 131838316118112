import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

const trackCartEmpty = () =>
  pushGTMEvent({
    event: 'Track',
    category: 'notification',
    action: 'notice_shown_in_cart',
    label: 'empty_cart',
    value: 0,
  });
export default trackCartEmpty;
